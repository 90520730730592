import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Titulo } from '../../styles/Defaults';
import AnimateContainer from '../../components/AnimateContainer';

import {
  CenterDiv, InputLabel, Input, SuggestionList,
} from './styles';
import * as LoginActions from '../../store2/modules/login/actions';

const queryClient = new QueryClient();

const Master = () => {
  const dispatch = useDispatch();
  const { masterData, master } = useSelector((state) =>
    state.login);
  const [users, setUsers] = useState([]);
  const [resultSearch, setResultSearch] = useState();

  useEffect(() => {
    setUsers(masterData);
  }, [masterData]);

  const onChangeSearch = (e) => {
    setResultSearch(users.filter((itemSearch) =>
      (itemSearch.nome.toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) >= 0
      || itemSearch.email?.toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) >= 0)
      && e.currentTarget.value !== ''));
  };
  const selectUser = (email) => {
    dispatch(LoginActions.selectUserMaster(email));
  };

  if (!master) {
    return <Redirect to="/Home" />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AnimateContainer>
        <Container>
          <Titulo style={{ margin: 0 }}>Master</Titulo>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '20vh',
          }}
          >
            <CenterDiv>
              <div>
                <InputLabel>Usuário: </InputLabel>
                <Input onChange={onChangeSearch} />
              </div>
            </CenterDiv>
            <CenterDiv>
              <SuggestionList>
                {resultSearch?.map((user) =>
                  (
                    <SuggestionList.Item
                      key={user.codusuario}
                      onClick={() =>
                        selectUser(user.email)}
                    >
                      <button type="button">
                        {user.nome}
                        {' '}
                        -
                        {' '}
                        {user.email}
                      </button>
                    </SuggestionList.Item>
                  ))}
              </SuggestionList>
            </CenterDiv>
          </div>
        </Container>
      </AnimateContainer>
    </QueryClientProvider>
  );
};

/* const MasterApp: React.FC = () =>
  (<QueryClientProvider client={queryClient}><Master /></QueryClientProvider>); */

export default Master;
